import React, {useState, useRef, useEffect} from "react";

import Row from "../components/bootstrap/row";
import Col from "../components/bootstrap/col";
import Grid from "../components/bootstrap/grid";
import TextField from "../components/formsy/text-field";
import FormsyHiddenField from "../components/formsy/hidden";
import { fetch } from "window-or-global";

const apiUrl = process.env.HS_APIURI;

// app.get("/captcha.api", generateSessionCaptcha);
// app.get("/captcha.api/generate", generateJSONRequest);
// app.get("/captcha.api/image/:captchaKey", generateImage);
// app.get("/captcha.api/destroy/:captchaKey", destroyCaptcha);

export default function Captcha(props) {
  const [captchaKey, setCaptchaKey] = useState();

  // const txtCaptcha = useRef();

  async function generateCaptcha() {
    if (captchaKey && captchaKey !== "") {
      await fetch(`${apiUrl}captcha.api/destroy/${captchaKey}`);
    }
    const capGenRes = await fetch(`${apiUrl}captcha.api/generate`);
    if (capGenRes.status === 200) {
      const {key} = await capGenRes.json();
      setCaptchaKey(key);
    }
  }

  useEffect(() => {
    generateCaptcha();
  }, [props.refreshKey]);
  if (!captchaKey) {
    return (<React.Fragment/>);
  }
  let uri = `${apiUrl}captcha.api/image/${captchaKey}`;

  
  
  function refresh() {
    generateCaptcha();
    //return setState({...state, date: new Date()});
  }

  return (<>
    <FormsyHiddenField name="captchaKey" val={captchaKey}/>
    <Grid fluid className="no-gutters">
      <Row>
        <Col className="vw-captcha-image" style={{minHeight: 50}}>
          <a
            className="vw-button white-blue"
            onClick={() => generateCaptcha()}>
            <i
              style={{
                transform: "translateY(65%)", marginRight: 10, cursor: "pointer"
              }}
              className="fal fa-sync"
            />
          </a>
          <img
            key={captchaKey}
            src={uri}
            className="img-fluid pull-left"
          />
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col xs={12}>
          <TextField
            name="captcha"
            label="Captcha"
            type="number"
            fullWidth
            pattern="[a-zA-Z0-9]+"
            inputMode="numeric"
            placeholder="Enter captcha"
            autoComplete="off"
            required
          />
        </Col>
      </Row>
    </Grid>
  </>);
}
