import React from "react";

import {withFormsy} from "formsy-react";
class FormsyHidden extends React.Component {
  constructor(props) {
    super(props);
    if(props.val) {
      return props.setValue(props.val);
    }
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.val !== this.props.val) {
      this.props.setValue(this.props.val);
    }
  }
  render = () => {
    return (<input name={this.props.name} type="hidden" value={this.props.val} />);
  }
}

export default withFormsy(FormsyHidden);
