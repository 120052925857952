import React from "react";

export default function Grid(props) {
  const {children, className, fluid, noGutters, ...rest} = props;
  return (
    <div className={`container${fluid ? "-fluid" : ""}${noGutters ? " no-gutters" : ""} ${className ? className : ""}`} {...rest}>
      {children}
    </div>
  );
}

Grid.displayName = "Grid";
