import React from "react"
import Manager from "../components/manager";
import Control from "../controls/register";

import Theme from "../configs/theme";
import config from "../configs/config.json";

export default function LoginPage() {
  return (
    <Manager name="login" title="Register">
      <Theme>
        <Control
          {...config}
        />
      </Theme>
    </Manager>
  );
}
