import React, {useRef} from "react";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import {Link} from "gatsby";

import Captcha from "../components/captcha";
import Button from "../components/bootstrap/button";
import FormsyTextField from "../components/formsy/text-field";
import sysConfig from "../configs/system.json";

export default function RegisterPreview(props) {
  const {disableForm, registerFields, registerButtonBgColour, registerButtonLabel} = props;
  const bgColor = {
    backgroundColor: registerButtonBgColour,
  }
  const [errorMessage, setErrorMessage] = useAsyncSetState(null);
  const [loading, setLoadingAsync] = useAsyncSetState(false);
  const [registerInfo, setRegisterInfo] = useAsyncSetState(undefined);
  const [captchaRefreshKey, setRefreshKey] = useAsyncSetState(undefined);
  const [token, setToken] = useAsyncSetState(null);
  const {t} = useTranslation();
  const authFormEl = useRef(null);
  const [creds, setCreds] = useAsyncSetState({
    username: "",
    password: "",
    dst: ""
  });

  async function handleValidSubmit(data) {
    try {
      if (disableForm) {
        return;
      }

      await setLoadingAsync(true);
      const response = await fetch(`${sysConfig.baseUri}rest.api/register`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign({api: true}, data)),
      });
      console.log("response", response)
      switch(response.status) {
        case 503:
          await setErrorMessage(() => (<>
            {"This email address already registered, "}
            <Link to="/forgot-password">{"Click Here"}</Link>{" to reset your password"}
          </>));
          await setRefreshKey(new Date());
          break;
        case 401:
          await setRefreshKey(new Date());
          const {error} = await response.json();
          switch(error) {
            case "Email is already registered":
              await setErrorMessage(() => (<>
                {"This email address already registered, "}
                <Link to="/forgot-password">{"Click Here"}</Link>{" to reset your password"}
              </>));
              break;
            default:
              setErrorMessage(error);
              break;
          }
          break;
        case 200:
          const {userName} = await response.json();
          return window.location = `/welcome?firstName=${data.firstName}&userName=${userName}`;
      }
    } catch (err) {
      console.log("err", err);
      setErrorMessage("An error has occurred");
    }
  }

  if (registerInfo) {
    return (
      <Container className="form-spacer">
        <Row>
          <Col className="username">
            <label>{"Username:"}</label>
            <span>{registerInfo.userName}</span>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>      
      <Formsy onValidSubmit={(data) => handleValidSubmit(data)}>
        <Container fluid className="form-spacer no-gutters">
          <Row>
            <Col>
              <h2 className="register-header">{"Register your account"}</h2>
            </Col>
          </Row>
          
          {(registerFields || []).map((field) => {
            let name = field.name;
            if (!name || name === "") {
              name = field.id;
            }

            switch (field.type) {
              case "text":
                return (
                  <Row key={field.id} className="field-row">
                    <Col>
                      <FormsyTextField
                        key={field.id}
                        name={name}
                        label={field.label}
                        placeholder={field.label}
                        validations={name === "email" ? "isEmail" : undefined}
                        validationError="A valid email address is required"
                        required
                        {...field.props}
                      />
                    </Col>
                  </Row>
                );
              case "password":
                return (
                  <Row key={field.id} className="field-row">
                    <Col>
                      <FormsyTextField
                        key={field.id}
                        type="password"
                        key={field.id}
                        name={name}
                        label={field.label}
                        placeholder={field.label}
                        validationError="A password must be minimum of 6 characters"
                        validations="minLength:6"
                        required
                        {...field.props}
                      />
                    </Col>
                  </Row>
                );
            }
            return undefined;
          })}
          <Row className="field-row">
            <Col>
              <Captcha name="captcha" refreshKey={captchaRefreshKey} />
            </Col>
          </Row>
          {errorMessage && (<Row>
            <Col>
              <div className="alert alert-danger">
                {errorMessage}
              </div>
            </Col>
          </Row>)}
          <Row>
            {/* <Col xs={6} className="align-items-center d-flex">
              <div className="field-note">{"* mandatory fields"}</div>
            </Col> */}
            <Col className="ml-auto" xs={"auto"}>
              <Button
                type={disableForm ? "button" : "submit"}
                style={bgColor}
                className="d-block btn-submit btn-register"
                colour="primary">
                {registerButtonLabel || "Continue"}
              </Button>
            </Col>
          </Row>
          {/* <Row className="divider mb-4">
            <Col xs={4}>
              <div className="line" />
            </Col>
            <Col className="or text-center">
              {"Or connect with"}
            </Col>
            <Col xs={4}>
              <div className="line" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <a href="" className="btn connect-with-btn facebook ml-auto mr-2">
                <i className="fab fa-facebook mr-2" />
                {"Facebook"}
              </a>
            </Col>
            <Col xs={12} sm={6}>
              <a href="" className="btn connect-with-btn google mr-auto ml-2">
                <i className="fab fa-google mr-2" />
                {"Google"}
              </a>
            </Col>
          </Row> */}
          <hr/>
          <Row className="login-button-row">
            <Col>
              <Link className="return-link" to="/login/">
                {"Return to the Login Screen"}
              </Link>
            </Col>
          </Row>
        </Container>
      </Formsy>
    </>
  );
}
